import Cookies from 'js-cookie';
import $bus from '@base/plugins/EventBus';
import {addPublicRequestParam} from '@base/util/common';
import {ueListKeywords} from '@base/constants/common';

import config from '~/environment.js';

let protocol = 'https:';
if (!process.server) {
    protocol = window.location.protocol;
}
let BASE_URL = '';
BASE_URL = protocol + config.env.proxyUrl;

export default function ({$axios, redirect, store}) {
    $axios.interceptors.request.use(config => {
        const {os = 'h5-pc', p_client = 18} = store.state;

        const isUeUrl = ueListKeywords.some(item => config.url.includes(item));
        // 只针对UE 服务所有接口添加os
        const publicParams = isUeUrl
            ? {
                p_client,
                os
            }
            : {p_client};

        addPublicRequestParam(config, publicParams);

        const IS_TOKEN = Cookies.get('__user_token__');
        IS_TOKEN && (config.headers.sid = IS_TOKEN);
        // 设置 ldc 信息
        config.headers.ldc = Cookies.get('ldc');

        try {
            const cachedUserInfo = Cookies.get('__user_info__');
            if (cachedUserInfo) {
                const userInfo = JSON.parse(cachedUserInfo);
                const UID = userInfo.userId;
                UID && (config.headers.UID = UID);
            }
        }
        catch (e) {
            const error = {
                tag: 'axios',
                config,
                errorMessage: e.toString(),
            };
            console.error(JSON.stringify(error));
        }

        return config;
    }, error => Promise.reject(error));
    $axios.defaults.timeout = 50000;
    // $axios.onRequest(config => {});

    $axios.onResponse(response => {
        if (response.data) {
            // store.commit("STATER", Object.assign({}, response.data))
            $bus.$emit('excHandler', response.data);
        }
    });

    $axios.onError(error => {
        const code = parseInt(error.response && error.response.status, 10);
        if (code === 400) {
            redirect('/400');
        }
    });
}
