import api from '~/api';

const state = () => ({
    title: '班级列表 - 高途素养',
    res: {},
    pageSize: 15,
    finished: true,
    isFetching: false,
    clazzList: []
});

const mutations = {
    IS_FETCHING(state, payload) {
        state.isFetching = payload;
    },
    SUBLIST_FINISHED(state, payload) {
        state.finished = payload;
    },
    CLAZZLIST(state, payload) {
        state.clazzList = payload;
    },
    RES(state, payload) {
        state.res = payload;
    }
};

const actions = {
    async loadSublistData({commit, state, getters}, payload) {
        const params = {
            course_id: payload.course_id,
            pageSize: state.pageSize
        };
        if (payload.last_course_id) {
            params.last_course_id = payload.last_course_id;
        }
        else {
            commit('IS_FETCHING', true);
        }
        await this.$axios.$get(api.course.sublist, {params}).then(res => {
            commit('IS_FETCHING', false);
            if (res.status === 0) {
                if (params.last_course_id) {
                    commit('CLAZZLIST', [...state.clazzList, ...res.clazz_list]);
                }
                else {
                    commit('CLAZZLIST', res.clazz_list || []);
                    commit('RES', {...res});
                }
                commit('SUBLIST_FINISHED', false);
                if (res.clazz_list.length < state.pageSize) {
                    commit('SUBLIST_FINISHED', true);
                }
            }
        });
    }
};

export default {
    actions,
    state,
    mutations
};
