import api from '~/api';
import qs from 'qs';
import Cookies from 'js-cookie';
import timeUtil from '~/assets/js/study/calendar';

const state = () => ({
    title: '我的课表 - 高途素养',
    course_start_time: new Date(timeUtil.dateFormat(new Date())).getTime() / 1000,
    taskCount: {}
});

const mutations = {
    START_TIME(state, payload) {
        // if(JSON.stringify(payload.live) !== '{}') {
        state.course_start_time = payload.recentLiveVO.courseStartTime;
        Cookies.set('course_start_time', payload.recentLiveVO.courseStartTime || Math.floor(new Date().getTime() / 1000));
        // }
        state.taskCount = payload.unfinishedTaskNumberVO;
    },
};

const actions = {
    async getStartTime({commit}, payload) {
        const params = {
            sid: Cookies.get('__user_token__'),
            isFromPC: true
        };
        await this.$axios.$post(api.study.center, qs.stringify(params)).then(res => {
            if (res.code === 0) {
                commit('START_TIME', res.data);
            }
        });
    }
};

export default {
    state,
    mutations,
    actions
};
